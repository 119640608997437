import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Ffms from "@components/Projects/Ffms";

const FfmsPage = () => {
  const ffmsRef = useRef(null);

  return (
    <>
      <div className='fixed' ref={ffmsRef}/>
      <Header/>
      <Ffms/>
      <Footer/>
    </>
  );
};

export default FfmsPage;