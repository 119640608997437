import React, {useEffect, useState} from 'react'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Spinner from "@assets/animations/spinner.json";
import Lottie from "react-lottie";
import Layout from "@hoc/layouts/Layout";
import samplePDF from '@assets/pdf/gender-equality.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Loader = () => {
  return (
    <div>
      <Lottie
        ariaRole="div"
        isClickToPauseDisabled
        options={{
          loop: true,
          autoplay: true,
          animationData: Spinner,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
      />
    </div>
  )
}

const GenderEquality = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handlePrevButton = () => {
    if (pageNumber === 1) {
      return
    }
    setPageNumber((prevState => prevState - 1))
  }

  const handleNextButton = () => {
    if (pageNumber === numPages) {
      return
    }
    setPageNumber((prevState => prevState + 1))
  }

  const nextPrevButtons = () => (
    <>
      <button
        id="terms-and-conditions-prev-page"
        onClick={handlePrevButton}
        disabled={pageNumber === 1}
        type="button"
        className="p-2 bg-gray-700 bg-opacity-80 focus:outline-none disabled:opacity-50 transform hover:-translate-x-0.5 text-white rounded-full"
      >
        <FaChevronLeft className="h-3 w-3"/>
      </button>

      <p className={'px-10 text-white font-montserrat'}>{pageNumber}/{numPages}</p>

      <button
        id="terms-and-conditions-next-page"
        onClick={handleNextButton}
        disabled={pageNumber === numPages}
        type="button"
        className="p-2 bg-gray-700 bg-opacity-80 focus:outline-none disabled:opacity-50 transform hover:-translate-x-0.5 text-white rounded-full"
      >
        <FaChevronRight className="h-3 w-3"/>
      </button>
    </>
  )

  useEffect(() => {const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <Layout>
        <div className="fixed"/>
        <div className="text-white font-montserrat relative mb-10">
          <div className="mt-32 mb-10 font-semibold tracking-wide text-3xl md:text-5xl text-center text-white text-opacity-90 uppercase fade-in-text">Gender Equality</div>
          <div className="whitespace-pre-line lg:px-50 md:px-20 px-0 pt-5 text-dark-blue text-lg">
            <div className={'flex justify-center items-center mb-5 px-10'}>
              {nextPrevButtons()}
            </div>
            <div className="flex justify-center items-center">
              <Document
                file={samplePDF}
                loading={<Loader/>}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
              >
                <Page
                  className={'border'}
                  pageNumber={pageNumber}
                  loading={<Loader/>}
                  width={isMobile ? window.innerWidth * 0.8 : undefined}
                />
              </Document>
            </div>
            <div className={'flex justify-center items-center mt-5 px-10'}>
              {nextPrevButtons()}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default GenderEquality
