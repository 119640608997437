import React, {useEffect, useState} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const OurTechnology = () => {
  const dispatch = useDispatch();
  const [technologies, setTechnologies] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect(() => {
    dispatch(showSpinner())
    CMSAPI.get(`/technologies?populate=deep`).then(response => {
      setTechnologies(_.get(response, 'data.data', []));
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch])

  return (
    <div className="w-full h-full bg-[#000002] pb-10">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto pt-10 md:pt-24">
        <div className='text-white leading-4 tracking-wide text-xl font-sans text-center uppercase'
             data-aos="fade-right" data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">OUR TECHNOLOGY
          STACK
        </div>
        <div className='mt-2 text-white text-4xl lg:text-5xl text-center uppercase' data-aos="flip-left"
             data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">Why you choose our plugin
        </div>
        <div className="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-y-6 md:gap-y-16 m-5 md:m-20 gap-x-3">
          {technologies.map((technology) => (
            <div className='flex flex-col justify-center items-center' data-aos="flip-down" data-aos-offset="50"
                 data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
              <img src={technology?.attributes?.icon?.data?.attributes?.url} title={technology?.attributes?.title}
                   className='w-[90px] h-[90px] md:w-[150px] md:h-[150px] bg-transparent mt-10 md:mt-0 bg-gradient-to-b from-transparent to-[#ffffff1a] bg-gradient-[137deg] rounded-3xl border border-[#ffffff1a] align-middle items-center justify-center drop-shadow-lg p-6 md:p-10 hover:'
                   alt='java'/>
              <div
                className='mt-4 tracking-wider text-white font-semibold md:text-lg text-sm font-sans'>{technology?.attributes?.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default OurTechnology;

