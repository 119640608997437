import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Pok from "@components/Projects/Pok";

const PokPage = () => {
  const pokRef = useRef(null);

  return (
    <>
      <div ref={pokRef} className='fixed'/>
      <Header/>
      <Pok/>
      <Footer/>
    </>
  );
};

export default PokPage;