import React, {useEffect, useRef, useState} from "react";
import AboutUs from "@components/AboutUs/AboutUs";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import MissionAndVision from "@components/AboutUs/MissionAndVision";
import Partners from "@components/AboutUs/Partners";
import Team from "@components/AboutUs/Team";
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const aboutUsRef = useRef(null);
  const sectionRef = useRef(null);

  const [data, setData] = useState({});

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/about-us?populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    }).finally(() => dispatch(hideSpinner()));
  }, [dispatch]);

  return (
    <>
      <div ref={aboutUsRef} className='fixed'/>
      <Header/>
      <AboutUs data={data?.header}/>
      <MissionAndVision data={data}/>
      <Team sectionRef={sectionRef} title={data?.teamTitle}/>
      <Partners title={data?.partnersTitle}/>
      <Footer/>
    </>
  );
}

export default AboutUsPage;
