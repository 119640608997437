import React, {useEffect, useState} from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import CMSAPI from "@utils/plugins/CMSAPI";
import _ from "lodash";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const Partner = ({partner, ...props}) => {
  return (
    <a {...props} href={partner.website} target="_blank" rel="noreferrer" className="flex h-full">
      <img
        src={partner?.image?.data?.attributes?.url}
        alt={partner.name}
        className="w-auto object-contain w-40 lg:opacity-90 opacity-100 hover:opacity-100 duration-700"
      />
    </a>
  );
};

const Partners = ({title}) => {
  const dispatch = useDispatch();
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    dispatch(showSpinner())
    CMSAPI.get(`/partners?populate=deep`).then(response => {
      setPartners(_.get(response, 'data.data', []));
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch])

  const responsive = {
    superLargeDesktop: {
      breakpoint: {max: 2560, min: 1024},
      items: 4
    },
    desktop: {
      breakpoint: {max: 1024, min: 768},
      items: 3
    },
    tablet: {
      breakpoint: {max: 768, min: 500},
      items: 2
    },
    mobile: {
      breakpoint: {max: 500, min: 0},
      items: 1
    }
  };

  return (
    <div
      className="3xl:mx-auto 3xl:px-60 align-items-center bg-[#000002] md:pb-10 flex mx-auto max-w-[1700px] flex-col xl:pt-10">
      <div
        className="font-semibold tracking-wide text-3xl md:text-5xl xl:text-6xl text-white leading-7 mt-16 text-center text-opacity-80">
        {title}
      </div>
      <div className="px-15 py-10 md:pt-24 md:pb-32 xl:pb-16">
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          itemClass="xs:px-10 px-3">
          {partners.map((partner, key) => (
            <Partner partner={partner?.attributes} key={key} id={partner?.id}/>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export default Partners;