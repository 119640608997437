import React from 'react';
import Tilt from "react-parallax-tilt";
import gradient from "@assets/svg/gradient.svg";
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const Contact = ({data = {}, buttonRef}) => {

  return (
    <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div
        className="flex pt-50 md:pt-34 3xl:pt-50 pb-60 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div className="flex flex-col justify-center text-center items-center self-center relative">
          {data ? <div
            className="font-semibold tracking-wide text-3xl md:text-6xl lg:text-[5.2rem] text-white text-opacity-90 lg:mt-10 uppercase flicker w-full xl:w-2/3 px-5 lg:px-10 xl:px-0">
            {data?.title}
          </div> : <SkeletonLoader count={3}/>
        }
        <Tilt
          trackOnWindow
          className="tilt"
          tiltMaxAngleX={30}
          tiltMaxAngleY={30}
          perspective={800}
          transitionSpeed={500}
          scale={1.1}
          glareReverse
          gyroscope
        >
          {data ? <button ref={buttonRef} className='mt-10 lg:self-center md:px-18 py-2.5 contact-button' onClick={() => {
            window.open("mailto:" + 'info@nebula.al')
          }}>
            <span className='hover:animate-bounce font-sans button-text'>{data?.button}</span>
          </button> : <SkeletonLoader count={1}/>}
        </Tilt>
      </div>
    </div>
</div>
)
}

export default Contact;

