import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Services from "@components/Services/Service";
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const ServicesPage = () => {
  const serviceRef = useRef(null);

  const dispatch = useDispatch()
  const [services, setServices] = useState([]);

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/services?populate=deep`).then(response => {
      setServices(response.data.data)
    }).finally(()=> dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <>
      <div className='fixed' ref={serviceRef}/>
      <Header/>
      <Services services={services}/>
      {services && <Footer/>}
    </>
  );
}

export default ServicesPage;
