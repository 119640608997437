import React, { useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import AOS from "aos";
import { Parallax } from "react-parallax";

const Tag = ({ content, ...props }) => (
  <div {...props}>
    <div className="group">
      <button
        className="flex bg-white items-center justify-center rounded-full border-0.5 px-4 h-5 cursor-default"
      >
          <span
            className="text-center whitespace-nowrap text-xxs xl:text-xs uppercase font-montserrat">
            {content}
          </span>
      </button>
    </div>
  </div>
)
const Project = ({ bgImage, title, year, location, tags, homePageRef, extraClasses }) => {
  const history = useHistory();
  const containerRef = useRef(null);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div ref={containerRef}
         className={`hover:cursor-pointer relative md:mt-0 flex m-3 overflow-hidden shadow-xl w-[300px] h-[350px] xl:w-[520px] xl:h-[600px] rounded-3xl border border-[#ffffff1a] hover:cursor-cursor-grow ${extraClasses}`}
         onClick={() => history.push(location)}>
      <Parallax bgImage={bgImage} strength={150}>
        <div className="w-[300px] h-[300px] xl:w-[520px] xl:h-[560px]">
          <div className="flex absolute top-0 mx-5 my-5 lg:m-10 tracking-widest uppercase text-white">{year}
          </div>
          <div className='flex flex-col uppercase absolute bottom-0 ml-3 xl:ml-10'>
            <div className="tracking-wider text-white font-semibold text-4xl uppercase">{title}</div>
            <div
              className="flex mt-2 space-x-3"
              style={{ zIndex: 2 }}
            >
              {tags.map((tag) => (
                <Tag content={tag?.name}/>
              ))}
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  )
}

export default Project;

