import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import One from "@components/Projects/One";

const OnePage = () => {
  const oneRef = useRef(null);

  return (
    <>
      <div ref={oneRef} className='fixed'/>
      <Header/>
      <One/>
      <Footer/>
    </>
  );
};

export default OnePage;