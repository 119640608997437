import React from 'react';
import '@assets/css/style.css';

const HomeHeader = ({data}) => {

  return (
    data ? <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
      <div
        className="flex pt-16 md:pt-56 pb-73 md:pb-20 xl:pb-60 3xl:pt-120 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div className="container mask-image absolute">
          <div className="home-header-gradient"></div>
        </div>
        <div className="flex justify-center text-center items-center self-center relative">
          <div
            className="font-semibold tracking-wide text-4xl md:text-6xl lg:text-[5.2rem] text-white text-opacity-90 lg:mt-10 uppercase fade-in-text">
            Make your
            <div className='-mt-2.5'>digital dreams</div>
            <div className='-mt-2.5 ml-8'>a reality</div>
          </div>
        </div>
      </div>
    </div> : null
  )
}

export default HomeHeader;

