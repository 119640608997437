import {combineReducers} from 'redux';
import authenticationReducer from './authentication/Reducer';
import meReducer from './me/Reducer';
import spinnerReducer from './spinner/Reducer';

const Reducers = combineReducers({
    authenticationReducer,
    meReducer,
    spinnerReducer,
});

export default Reducers;
