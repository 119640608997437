import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Smip from "@components/Projects/Smip";

const SmipPage = () => {
  const smipRef = useRef(null);

  return (
    <>
      <div ref={smipRef} className='fixed'/>
      <Header/>
      <Smip/>
      <Footer/>
    </>
  );
};

export default SmipPage;