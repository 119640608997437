import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import HomeHeader from "@components/Home/HomeHeader";
import OurPlugin from "@components/Home/OurPlugin";
import OurServices from "@components/Home/OurServices";
import Revolutionize from "@components/Home/Revolutionize";
import OurTechnology from "@components/Home/OurTechnology";
import OurProjects from "@components/Home/OurProjects";
import Footer from "@hoc/partials/Footer";
import "@assets/css/style.css"
import GradientTop from '@assets/svg/gradient_top.svg';
import GradientLeft from '@assets/svg/gradient_top.svg';
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const HomePage = () => {
  const dispatch = useDispatch();

  const homePageRef = useRef(null);
  const sectionRef = useRef(null);

  const [data, setData] = useState({});

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/home-page?populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <>
      <div ref={homePageRef} className='fixed'/>
      <img src={GradientTop} alt="" className="absolute opacity-50" style={{ top: '-700px', left: '-900px' }}/>
      <img src={GradientLeft} alt="" className="absolute opacity-30" style={{ top: '800px', left: '-900px' }}/>
      <Header/>
      <HomeHeader data={data?.whyNebula}/>
      <OurPlugin data={data?.whyNebula}/>
      <OurProjects homePageRef={homePageRef}/>
      <Revolutionize data={data?.revolutionize}/>
      <OurServices sectionRef={sectionRef}/>
      <OurTechnology/>
      <Footer/>
    </>
  );
}

export default HomePage;