import React, {useEffect} from 'react';
import gradient from "@assets/svg/gradient.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'
import SkeletonLoader from "@core/loaders/SkeletonLoader";

const AboutUs = ({data = {}}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div
        className="flex pt-30 md:pt-44 mx-3 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
        <div
          className="grid grid-cols-1 lg:grid-cols-2 justify-center text-center items-center self-center relative px-3">
          <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true"
               className="w-full xl:w-4/5 font-semibold text-left lg:ml-20 xl:ml-40 uppercase tracking-wide text-4xl md:text-6xl xl:text-7xl text-white text-opacity-90 lg:mt-10">
            {data ? data[0]?.title : <SkeletonLoader count={2}/>}
          </div>
          <div
            className='mt-10 text-white font-thin text-base md:text-lg xl:text-2xl leading-tight lg:text-justify lg:w-4/5'
            data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
            data-aos-once="true">
            {data ? data[0]?.description : <SkeletonLoader count={4}/>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs;




