import React, {useEffect, useState} from 'react';
import '@assets/css/style.css';
import { Link } from "react-router-dom";
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const OurServices = ({ sectionRef }) => {

  const Service = ({ icon, title, description }) => {
    return (
      <div className='card'>
        <div className='img-box flex flex-col justify-between'>
          <img src={icon} className='icon' alt="icon"/>
          <div className="text-white font-inter card-text">{title}</div>
        </div>
        <div className='content'>
          <div className='text-white mt-5 font-inter overflow-hidden'
               style={{
                 overflow: 'hidden',
                 display: '-webkit-box',
                 WebkitBoxOrient: 'vertical'
          }}>
            {description}
          </div>
        </div>
      </div>
    );
  };

  const dispatch = useDispatch();
  const [services, setServices] = useState([]);

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/services?populate=deep`).then(response => {
      setServices(response.data.data)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className='relative w-full h-full max-width-[1700px]'>
      <div className="flex w-full justify-center items-center lg:pt-14" ref={sectionRef}>
        <div className="flex w-full flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
          <div className="flex justify-between w-full">
            <div
              className='mt-2 text-white text-left text-3xl lg:text-4xl lg:text-5xl font-semibold ml-2 md:ml-16 lg:ml-24 xl:ml-10'
              data-aos="fade-up" data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500"
              data-aos-once="true">Our Services
            </div>
            <div
              className='text-[#0991db] text-sm md:text-base mt-3.5 md:mt-6 mr-2 md:mr-16 lg:mr-24 xl:mr-10'
              data-aos="fade-up"
              data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
              <Link to='/services'>
                {`{ Read more }`}
              </Link>
            </div>
          </div>
          <div className="overflow-hidden w-full justify-center items-center self-center mx-auto">
            <div className='row pl-20 md:pl-30 lg:pl-20 scrollbar-hidden'>
              {services.length && services.map((service) => {
                return <Service title={service?.attributes?.title} description={service?.attributes?.description}
                                icon={service?.attributes?.icon?.data?.attributes?.url}/>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurServices;
