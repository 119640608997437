// AUTH
export const ADD_AUTH = 'ADD_AUTH';
export const REMOVE_AUTH = 'REMOVE_AUTH';

// ME
export const ADD_ME = 'ADD_ME';
export const REMOVE_ME = 'REMOVE_ME';

// SPINNER
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';

