import React, {useEffect, useState} from 'react';
import pokApp from "@assets/svg/Projects/pok/pok_app.svg";
import holdingPok from "@assets/svg/Projects/pok/holding_pok.svg";
import mapOfPok from "@assets/svg/Projects/pok/map_of_pok.svg";
import pokLogin from "@assets/svg/Projects/pok/pok_login.svg";
import pokPhone from "@assets/svg/Projects/pok/pok_phone.svg";
import gradient from "@assets/svg/gradient.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'
import CMSAPI from "@utils/plugins/CMSAPI";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const Pok = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/pok?populate=deep`).then(response => {
      setData(response.data.data?.attributes)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className="w-full h-full bg-[#000002]">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div className="flex pt-7 md:pt-20 md:pb-16 mx-3 3xl:mx-auto max-w-[1700px] flex-col">
        <div className="flex py-6 3xl:mx-auto max-w-[1700px] flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-5 md:px-12 xl:px-28 pt-8">
            <div className="flex flex-col xl:ml-10 3xl:ml-22 px-7 md:px-0">
              <div data-aos="fade-up" data-aos-offset="50"
                   data-aos-easing="ease-in" data-aos-duration="500"
                   className="tracking-widest text-7xl font-semibold uppercase">{data ? data?.title :
                <SkeletonLoader count={1}/>}
              </div>
              <div className="flex mt-3 text-white text-sm uppercase tracking-wide justify-between w-40 px-1">
                <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in"
                     data-aos-duration="500" data-aos-once="true">{data ? data?.typeOfProject :
                  <SkeletonLoader count={1}/>}
                </div>
                <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                     data-aos-once="true">{data ? data?.year : <SkeletonLoader count={1}/>}
                </div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className='flex flex-col mt-2 text-base lg:text-lg font-thin w-full xl:w-5/6 z-30'>
              {data ? data?.description : <SkeletonLoader count={5}/>}
              <div data-aos="fade-up" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                   data-aos-once="true"
                   onClick={() => {
                     window.open(data?.website)
                   }}
                   className='text-[#0991DB] hover:underline hover:cursor-pointer text-base mt-1 font-extrathin'>{data ? data?.website :
                <SkeletonLoader count={5}/>}</div>
            </div>
          </div>
          <div className="z-30 grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-5 md:px-12 xl:px-28 pt-2 xl:pt-8">
            <img data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 src={data?.firstImage?.data?.attributes?.url} alt="POK App"
                 className='xl:ml-10 3xl:ml-0 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[862.1px] rounded-lg'/>
            <img data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 src={data?.secondImage?.data?.attributes?.url} alt="Holding Pok"
                 className='mt-5 md:mt-0 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[862.1px] rounded-lg'/>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-8 px-5 md:px-12 xl:px-28 pt-2 md:pt-8">
            <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className="xl:ml-10 3xl:ml-22 font-bold text-2xl md:text-4xl lg:text-5xl text-[#d0eaf8] leading-7 text-center md:text-left lg:w-1/2">
              {data ? data?.firstParagraph?.title : <SkeletonLoader count={1}/>}
            </div>
            <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className='mt-5 md:mt-0 text-white font-thin text-base lg:text-lg leading-tight md:text-justify w-full self-center w-full md:w-5/6'>
              {data ? data?.firstParagraph?.description : <SkeletonLoader count={5}/>}
            </div>
          </div>
          <img data-aos="fade-up" data-aos-offset="150" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true" src={data?.bannerImage?.data?.attributes?.url}
               alt="Map of Pok"
               className='w-full rounded-lg ml-5 md:ml-12 xl:ml-38 3xl:ml-48 w-[270px] md:w-[650px] lg:w-[900px] xl:w-[1070px] 3xl:w-[1400px] mt-12'/>
          <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-2 xl:px-28 pt-8">
            <div className='md:ml-10 3xl:ml-22 '>
              <img data-aos="fade-right" data-aos-offset="130" data-aos-easing="ease-in" data-aos-duration="500"
                   data-aos-once="true"
                   src={data?.secondParagraph?.image?.data?.attributes?.url} alt="POK App"
                   className='w-[300px] lg:w-[561px] lg:h-[561px] rounded-lg'/>
            </div>
            <div data-aos="fade-left" data-aos-offset="70" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className='mt-5 md:mt-0 text-white font-thin text-sm md:text-base lg:text-xl leading-tight md:text-justify w-full md:w-4/5 3xl:w-full mx-auto self-center'>
              {data ? data?.secondParagraph?.description : <SkeletonLoader count={5}/>}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-8 lg:mt-2.5 px-2 xl:px-28">
          <div data-aos="fade-right" data-aos-offset="70" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true"
               className='text-white font-thin text-sm md:text-base lg:text-xl leading-tight md:text-justify w-full md:w-4/5 3xl:w-full mx-auto self-center md:ml-10 3xl:ml-22'>
            {data ? data?.thirdParagraph[0]?.description : <SkeletonLoader count={5}/>}
          </div>
          <div data-aos="fade-left" data-aos-offset="130" data-aos-easing="ease-in" data-aos-duration="500"
               data-aos-once="true"
               className='mt-5 md:mt-0 flex 3xl:justify-end lg:justify-start justify-end'>
            <img src={data?.thirdParagraph[0]?.image?.data?.attributes?.url} alt="POK App"
                 className='rounded-md w-[300px] lg:w-[561px] lg:h-[561px] rounded-lg'/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pok