import React, {useEffect, useRef, useState} from 'react';
import gradient from "@assets/svg/gradient.svg";
import Project from "@components/Projects/partials/Project";
import CMSAPI from "@utils/plugins/CMSAPI";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const Projects = ({pageRef}) => {
  const dispatch = useDispatch();
  const [firstHalf, setFirstHalf] = useState('');
  const [secondHalf, setSecondHalf] = useState('');
  const [projects, setProjects] = useState([]);

  useEffect( () => {
    dispatch(showSpinner());
    CMSAPI.get(`/projects-page?populate=deep`).then(response => {
      const length = response.data.data?.attributes?.title.length;
      const halfLength = Math.floor(length / 2);

      const firstHalf = response.data.data?.attributes?.title.slice(0, halfLength);
      const secondHalf = response.data.data?.attributes?.title.slice(halfLength);
      setFirstHalf(firstHalf);
      setSecondHalf(secondHalf)
    })
    CMSAPI.get(`/projects?populate=deep`).then(response => {
      setProjects(response.data.data)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className="w-full h-full bg-[#000002]">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div className="flex pt-10 md:pb-16 3xl:mx-auto max-w-[1700px] flex-col w-full">
        <div className="ml-4 space-y-4 z-30">
          <div className="w-full h-full md:py-10 lg:py-6 items-center self-center justify-center">
            <div
              className="w-full flex pt-16 3xl:mx-auto max-w-[1500px] flex-col items-center self-center justify-center">
              <div
                className="mx-5 md:mx-0 uppercase font-semibold tracking-wide text-2xl md:text-5xl xl:text-7xl text-white text-opacity-90 lg:mt-10 flicker">
                {firstHalf ? firstHalf : <SkeletonLoader count={1}/>}
                <br/>
                <div className='md:ml-34'>{secondHalf ? secondHalf : <SkeletonLoader count={1}/>}</div>
              </div>
              <div
                className='hidden md:block text-in-left absolute text-[0.65rem] xl:text-[0.85rem] font-inter top-51 left-20 lg:top-56 lg:left-52 xl:top-62 xl:left-64 3xl:left-[50rem] text-white text-left uppercase text-opacity-80'>
                Hardwork <br/>Passion <br/>Teamwork
              </div>
            </div>
          </div>
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-2 my-6 md:my-12 mx-auto gap-x-6 gap-y-6">
          {projects.length && projects.map((project, index) => {
            return <Project homePageRef={pageRef}
                            bgImage={project?.attributes?.image?.data?.attributes?.url}
                            extraClasses={index % 2 && 'md:mt-30'}
                            title={project?.attributes?.name}
                            location={project?.attributes?.link}
                            year={project?.attributes?.year}
                            tags={project?.attributes?.categories}
            />
          })}
        </div>
      </div>
    </div>
  )
}

export default Projects;

