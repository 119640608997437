import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Contact from "@components/Contact/Contact";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useDispatch} from "react-redux";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";

const ContactPage = () => {
  const contactRef = useRef(null);
  const buttonRef = useRef(null)

  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/contact-page?populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <>
      <div className='fixed' ref={contactRef}/>
      <Header/>
      <Contact data={data} buttonRef={buttonRef}/>
      <Footer/>
    </>
  );
}

export default ContactPage;
