import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import '@assets/css/style.css';

const FullPageSpinner = () => {
  const isActive = useSelector((state) => _.get(state, 'spinnerReducer.show', false));
  const canvasRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const scene = new THREE.Scene();
    let geometry = null;
    let material = null;
    let mesh = null;

    const generateGalaxy = () => {
      if (geometry) {
        geometry.dispose();
        if (material) {
          material.dispose();
        }
        if (mesh) {
          scene.remove(mesh);
        }
      }

      geometry = new THREE.BufferGeometry();
      const pointCount = 200000;
      const positions = new Float32Array(pointCount * 3);
      const scales = new Float32Array(pointCount);
      const colors = new Float32Array(pointCount * 3);
      const initialPositions = new Float32Array(pointCount * 3);
      const randomness = new Float32Array(pointCount * 3);

      const innerColor = new THREE.Color('#0b91db');
      const outerColor = new THREE.Color('#074A75');

      for (let i = 0; i < pointCount; i++) {
        const randomRadius = Math.random() * 10;
        const i3 = i * 3;

        const angle = ((i % 4) / 4) * Math.PI * 2;

        positions[i3] = Math.cos(angle) * randomRadius * 4;
        positions[i3 + 1] = 0;
        positions[i3 + 2] = Math.sin(angle) * randomRadius * 4;

        initialPositions[i3] = 0;
        initialPositions[i3 + 1] = 0;
        initialPositions[i3 + 2] = 0;

        scales[i] = Math.random();

        const pointColor = innerColor.clone();
        pointColor.lerp(outerColor, randomRadius / 10);

        colors[i3] = pointColor.r;
        colors[i3 + 1] = pointColor.g;
        colors[i3 + 2] = pointColor.b;

        randomness[i3] =
          Math.pow(Math.random(), 3.2) *
          (Math.random() > 0.5 ? -1 : 1) *
          0.85 *
          randomRadius;
        randomness[i3 + 1] =
          Math.pow(Math.random(), 3.2) *
          (Math.random() > 0.5 ? -1 : 1) *
          0.85 *
          randomRadius;
        randomness[i3 + 2] =
          Math.pow(Math.random(), 3.2) *
          (Math.random() > 0.5 ? -1 : 1) *
          0.85 *
          randomRadius;
      }

      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      geometry.setAttribute('initialPosition', new THREE.BufferAttribute(initialPositions, 3));
      geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1));
      geometry.setAttribute('aColor', new THREE.BufferAttribute(colors, 3));
      geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3));

      material = new THREE.ShaderMaterial({
        vertexShader: `
          uniform float uSize;
          uniform float uTime;
          uniform float uExplosionProgress;
          uniform float uRotationSpeed;
          uniform float uTimeOffset;

          attribute float aScale;
          attribute vec3 aColor;
          attribute vec3 aRandomness;
          attribute vec3 initialPosition;

          varying vec3 vColor;

          void main() {
            vec3 finalPosition = mix(initialPosition, position, uExplosionProgress);
            vec4 bodyPosition = modelMatrix * vec4(finalPosition, 1.0);

            float angle = atan(bodyPosition.x, bodyPosition.z);
            float distanceToCenter = length(bodyPosition.xz);
            float angleOffset = (1.0 / distanceToCenter) * (uTime + uTimeOffset) * uRotationSpeed;
            angle += angleOffset;

            bodyPosition.x = cos(angle) * distanceToCenter;
            bodyPosition.z = sin(angle) * distanceToCenter;
            bodyPosition.xyz += aRandomness.xyz;

            vec4 viewPosition = viewMatrix * bodyPosition;
            vec4 projectionPosition = projectionMatrix * viewPosition;

            gl_Position = projectionPosition;
            gl_PointSize = uSize * aScale;
            gl_PointSize *= (1.0 / -viewPosition.z);

            vColor = aColor;
          }
        `,
        fragmentShader: `
          varying vec3 vColor;
          
          void main() {
            float distanceFromCenter = 1.0 - distance(gl_PointCoord, vec2(0.5));
            distanceFromCenter = pow(distanceFromCenter, 4.0);

            vec3 finalColor = mix(vec3(0.0), vColor, distanceFromCenter);
            gl_FragColor = vec4(finalColor, 1.0);
          }
        `,
        blending: THREE.AdditiveBlending,
        sizeAttenuation: true,
        depthWrite: false,
        transparent: true,
        uniforms: {
          uSize: { value: 30 },
          uTime: { value: 0 },
          uExplosionProgress: { value: 0 },
          uRotationSpeed: { value: 1 },
          uTimeOffset: { value: 3.5 },
        },
      });

      mesh = new THREE.Points(geometry, material);
      scene.add(mesh);
    };

    generateGalaxy();

    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
    camera.position.set(3, 0.8, 1);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    scene.add(camera);

    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.dampingFactor = 1;
    controls.rotateSpeed = 0.05;

    const renderer = new THREE.WebGLRenderer({ canvas });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    const clock = new THREE.Clock();

    const tick = () => {
      const elapsedTime = clock.getElapsedTime();
      const progress = Math.min(elapsedTime / 5.0, 1.0);
      if (material) {
        material.uniforms.uTime.value = elapsedTime;
        material.uniforms.uExplosionProgress.value = progress;
      }
      controls.update();
      renderer.render(scene, camera);
      window.requestAnimationFrame(tick);
    };

    tick();

    const handleResize = () => {
      sizes.width = window.innerWidth;
      sizes.height = window.innerHeight;
      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (scene) {
        scene.children.forEach(child => {
          if (child instanceof THREE.Points) {
            child.geometry.dispose();
            child.material.dispose();
          }
          scene.remove(child);
        });
      }
      if (renderer) {
        renderer.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (isActive) {
      setShowLoader(true);
    } else {
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    }
  }, [isActive]);

  return (
    <div className={`fullPageSpinner ${!showLoader ? 'remove' : ''}`}>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default FullPageSpinner;

