import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Kesh from "@components/Projects/Kesh";

const KeshPage = () => {
  const keshRef = useRef(null);

  return (
    <>
      <div className='fixed' ref={keshRef}/>
      <Header/>
      <Kesh/>
      <Footer/>
    </>
  );
};

export default KeshPage;