import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'

const OurPlugin = ({data}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full bg-[#000002] pb-10">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto pt-10 md:pt-40 lg:pt-24">
        <div className="flex pb-6 mx-10 md:mx-20 lg:mx-32 3xl:mx-auto flex-col items-center self-center justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <div
              className="font-bold text-2xl md:text-4xl xl:text-5xl text-white leading-7 mb-7 text-center lg:text-left"
              data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
              data-aos-once="true">
              {data?.title}
            </div>
            <div
              className='lg:ml-32 text-white font-thin text-base md:text-lg xl:text-2xl leading-tight lg:text-justify lg:w-4/5'
              data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
              data-aos-once="true">
              {data?.description}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPlugin;

