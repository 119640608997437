import Axios from 'axios'
import {CMS_URL, CMS_TOKEN} from "@constants/index";

const CMSAPI = Axios.create({
  baseURL: CMS_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${CMS_TOKEN}`
  },
});

export default CMSAPI