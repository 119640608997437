import React, {useEffect, useState} from 'react';
import gradient from "@assets/svg/gradient.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'
import CMSAPI from "@utils/plugins/CMSAPI";

const Service = ({title, description, icon}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div
      className='py-6 px-2 md:p-6 lg:p-10 border-t border-[#ffffff1a] align-middle items-center shadow-2xl'>
      <div className="grid grid-cols-1 lg:grid-cols-2 justify-between">
        <div className='flex flex-row lg:flex-col justify-between my-5 lg:my-0'>
          <img src={icon} className='icon' alt="icon" data-aos="fade-right" data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"/>
          <div className="text-white uppercase text-base lg:text-xl xl:w-1/2 2xl:text-3xl" data-aos="fade-right"
               data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="300" data-aos-once="true">{title}</div>
        </div>
        <div data-aos="fade-left" data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"
             className="flex justify-end align-middle md:mt-3 self-center items-center font-light text-white mt-5 w-full text-xs md:text-sm lg:text-lg">
          {description}
        </div>
      </div>
    </div>
  )
};
const Services = ({services}) => {

  return (
    <div className="w-full h-full bg-[#000002]">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div className="flex pt-40 pb-20 3xl:mx-auto max-w-[1700px] flex-col mx-5 lg:mx-10 xl:mx-20">
        {services.length && services.map((service) => {
          return <Service title={service?.attributes?.title} description={service?.attributes?.description}
                          icon={service?.attributes?.icon?.data?.attributes?.url}/>
        })}
      </div>
    </div>
  )
}

export default Services;

