import React, {useEffect, useState} from 'react';
import '@assets/css/style.css';
import linkedIn from "@assets/svg/linked_In.svg"
import AOS from 'aos';
import 'aos/dist/aos.css'
import CMSAPI from "@utils/plugins/CMSAPI";
import {useDispatch} from "react-redux";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";

const Staff = ({linkedInName, team, fullName, position}) => {
  return (
    <div
      className={`hover:cursor-pointer relative overflow-hidden bg-cover bg-no-repeat group`}
      onClick={() => window.open(linkedInName)}>
      <img src={team} alt="team" className='relative rounded-sm'/>
      <img src={linkedIn} alt='linkedin logo'
           className='hidden transition duration-300 ease-in-out group-hover:block absolute top-2.5 right-2.5'/>
      <div
        className='text-white w-full text-2xl absolute bottom-10 left-4 font-sans tracking-wider font-light'>
        {fullName}
      </div>
      <div
        className='text-white w-full text-[14px] absolute bottom-3 left-4 uppercase font-inter font-light'>{position}
      </div>
      <div
        className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden team-img"></div>
    </div>
  );
};

const Team = ({sectionRef, title}) => {
  const dispatch = useDispatch();
  const [team, setTeam] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/teams?populate=deep`).then(response => {
      setTeam(response.data.data)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className='relative w-full h-full max-width-[1700px]'>
      <div className="flex w-full justify-center items-center lg:pt-14">
        <div className="flex w-full flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
          <div
            className="font-semibold tracking-wide text-3xl md:text-5xl xl:text-6xl text-white leading-7 mb-16 text-center text-opacity-80">
            {title}
          </div>
          <div className="team-wrapper" ref={sectionRef}>
            <section
              className='container team-container md:px-10 lg:px-30 xl:px-44 3xl:px-76'>
              {team.length && team.map((teamMember, index) => {
                return <Staff team={teamMember?.attributes?.image?.data?.attributes?.url}
                              fullName={teamMember?.attributes?.name}
                              linkedInName={teamMember?.attributes?.linkedIn}
                              position={teamMember?.attributes?.role}/>
              })}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team;
