import React, {useEffect, useState} from 'react';
import pok from "@assets/svg/Projects/pok/pok.svg"
import {Link} from "react-router-dom";
import '@assets/css/style.css';
import AOS from 'aos';
import 'aos/dist/aos.css'
import Project from "@components/Projects/partials/Project";
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const OurProjects = ({homePageRef}) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/projects?populate=deep&pagination[pageSize]=4`).then(response => {
      setProjects(response.data.data)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className="w-full h-full bg-[#000002] pb-10">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto lg:pt-10">
        <div
          className="flex w-full justify-center items-center lg:pb-18 md:pb-24">
          <div className="w-full flex flex-col items-center xl:w-4/5 [@media(min-width:1510px)]:w-3/4 2xl:my-10">
            <div className="flex justify-between w-full">
              <div
                className='mt-2 text-white text-left text-3xl lg:text-4xl lg:text-5xl font-semibold ml-2 md:ml-16 lg:ml-24 xl:ml-10'
                data-aos="fade-up" data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500"
                data-aos-once="true">Projects
              </div>
              <div
                className='text-[#0991db] text-sm md:text-base mt-3.5 md:mt-6 mr-2 md:mr-16 lg:mr-24 xl:mr-10¬'
                data-aos="fade-up"
                data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
                <Link to='/projects'>
                  {`{ See more }`}
                </Link>
              </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6 lg:gap-x-12 mt-6 lg:mt-12'>
              {projects.length && projects.map((project, index) => {
                return <Project homePageRef={homePageRef}
                                bgImage={project?.attributes?.image?.data?.attributes?.url}
                                extraClasses={index % 2 && 'md:mt-30'}
                                title={project?.attributes?.name}
                                location={project?.attributes?.link}
                                year={project?.attributes?.year}
                                tags={project?.attributes?.categories}
                />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurProjects;

