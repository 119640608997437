import React, { useEffect } from 'react';
import '@assets/css/style.css';
import lightBlob from '@assets/svg/blob/light_ellipse.svg';
import darkBlob from '@assets/svg/blob/dark_ellipse.svg';
import AOS from "aos";
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";

const Revolutionize = ({data}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full bg-[#000002] pb-10">
      <div className="flex max-w-[1700px] flex-col justify-center mx-auto pt-10 md:pt-0 lg:pt-10">
        <div className="flex pb-6 mx-5 lg:mx-32 3xl:mx-auto flex-col items-center self-center justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div
              className='flex 3xl:mx-auto w-3/5 self-center items-center justify-center mx-auto'>
              <div className='blob'>
                <img src={lightBlob} className='first-blob' alt='light blob'/>
                <img src={darkBlob} className='second-blob' alt='dark-blob'/>
              </div>
            </div>
            <div className='md:mt-15 text-center xl:text-left mx-5'>
              <div className='mt-2 text-white text-3xl xl:text-5xl' data-aos="flip-up" data-aos-offset="50"
                   data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">{data?.firstPartOfTitle}
              </div>
              <div className='lg:mt-2 text-white text-3xl xl:text-5xl' data-aos="flip-up" data-aos-offset="50"
                   data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"><span
                className="text-[#0991db]">{data?.colorfulTitle}<span className='text-white ml-2'>{data?.secondPartOfTitle}</span></span></div>
              <div className='mt-5 text-white text-left text-base xl:text-xl w-full' data-aos="flip-up"
                   data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
                {data?.description}
              </div>
              <div
                className='text-[#0991db] text-sm md:text-base mt-3.5 md:mt-6 mr-10 lg:mr-10 3xl:mr-120'
                data-aos="fade-up" data-aos-offset="40" data-aos-easing="ease-in" data-aos-duration="500"
                data-aos-once="true"
              >
                <Link to='/about-us'>
                  {data?.action}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Revolutionize;

