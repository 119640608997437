import React from 'react';
import Routes from '@router/index';
import Notification from "react-notify-toast";
import { BrowserRouter } from "react-router-dom";
import FullPageSpinner from "@hoc/partials/FullPageSpinner";

const App = () => {
  return (
    <BrowserRouter>
      <Notification options={{ zIndex: 2000, top: 10 }}/>
      <Routes/>
      <FullPageSpinner/>
    </BrowserRouter>
  );
};

export default App;
