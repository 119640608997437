import React from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";

const Layout = ({children}) => {
  return (
    // <div className="container">
    //   <div className="h-full w-full absolute shadow-inner">
    //     <Header/>
    //     <div className="flex justify-center">{children}</div>
    //     <Footer/>
    //   </div>
    // </div>
    <div className="container">
      <div className="h-full w-full absolute shadow-inner">
        <Header/>
        {children}
        <Footer/>
      </div>
    </div>
  );
};

export default Layout;
