import React, {useEffect, useRef, useState} from "react";
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Career from "@components/Career/Career";
import CMSAPI from "@utils/plugins/CMSAPI";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const CareersPage = () => {
  const dispatch = useDispatch();

  const careersRef = useRef(null);

  const [data, setData] = useState()

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/careers-page?populate=deep`).then(response => {
      const {attributes} = response.data.data;
      setData(attributes)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <>
      <div className='fixed' ref={careersRef}/>
      <Header/>
      <Career data={data}/>
      {data && <Footer/>}
    </>
  );
}

export default CareersPage;