import React, {useEffect} from 'react';
import arrowDown from '@assets/svg/arrow_down_right.svg'
import AOS from 'aos';
import 'aos/dist/aos.css'

const Card = ({title, description, longTitle}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div
      className={`${longTitle ? 'col-span-4' : 'col-span-3'} bg-transparent mt-10 md:mt-0 rounded-3xl align-middle items-center drop-shadow-lg`}>
      <img src={arrowDown} alt='Down arrow' className='mb-10' data-aos="flip-up" data-aos-offset="50"
           data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"/>
      <h1 className={`text-white text-3xl lg:text-4xl tracking-wide mt-5 ${longTitle ? 'w-1/2' : 'w-full'}`}
          data-aos="flip-up" data-aos-offset="50"
          data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">{title}</h1>
      <h1 className={`text-white ${longTitle ? 'mt-7' : 'mt-18'} text-base" data-aos="flip-up`} data-aos-offset="50"
          data-aos-easing="ease-in"
          data-aos-duration="500" data-aos-once="true">{description}</h1>
    </div>
  );
};
const MissionAndVision = ({data}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div className="w-full h-full bg-[#000002] pb-10">
      <div className="flex mx-auto max-w-[1700px] flex-col xl:pt-10">
        <div
          className="grid grid-cols-1 lg:grid-cols-11 mt-6 md:mt-34 mx-auto gap-y-3 md:gap-y-8 md:gap-x-16 px-6 md:pl-44 lg:pl-30 xl:pl-44 3xl:pl-64 md:pr-35 lg:pr-0 xl:pr-35">
          <Card title={data?.mission?.title}
                description={data?.mission?.description}/>
          <Card title={data?.vision?.title}
                description={data?.vision?.description}/>
          <Card title={data?.corporateResponsibility?.title} longTitle
                description={data?.corporateResponsibility?.description}/>
        </div>
      </div>
    </div>
  )
}

export default MissionAndVision;

