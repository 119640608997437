import React, { useRef } from 'react';
import Header from "@hoc/partials/Header";
import Footer from "@hoc/partials/Footer";
import Projects from "@components/Projects/Projects";

const ProjectsPage = () => {
  const projectsRef = useRef(null);

  return (
    <>
      <div ref={projectsRef} className='fixed'/>
      <Header/>
      <Projects pageRef={projectsRef}/>
      <Footer/>
    </>
  );
};

export default ProjectsPage;
