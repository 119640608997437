import React, {useEffect, useState} from 'react';
import arrow from "@assets/svg/arrow.svg"
import gradient from "@assets/svg/gradient.svg";
import 'aos/dist/aos.css'
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import AOS from "aos";
import CMSAPI from "@utils/plugins/CMSAPI";
import {useDispatch} from "react-redux";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";

const AvailablePosition = ({title, placeOfEmployment, applicationDueDate, typeOfEmployment, extraClasses}) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  return (
    <div
      className={`hover:cursor-pointer py-6 px-2 md:p-6 lg:p-10 border-t border-[#ffffff1a] align-middle items-center shadow-2xl ${extraClasses}`}>
      <div className="flex justify-between">
        <div className='flex flex-col'>
          <h1 className="text-white text-3xl lg:text-5xl tracking-wide" data-aos="fade-right" data-aos-offset="50"
              data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">{title}</h1>
          <div className="grid grid-cols-2 md:grid-cols-4 mt-5 md:space-x-8" data-aos="fade-up" data-aos-offset="50"
               data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true">
            <div className="flex flex-col text-white mt-3 w-full text-sm md:text-base lg:text-lg font-semibold">Place of
              employment
              <div className="flex flex-col text-white w-full text-sm lg:text-base font-light">{placeOfEmployment}</div>
            </div>
            <div
              className="flex flex-col text-white mt-3 w-full text-sm md:text-base lg:text-lg font-semibold">Application
              due date
              <div
                className="flex flex-col text-white w-full text-sm lg:text-base font-light">{applicationDueDate}</div>
            </div>
            <div
              className="flex flex-col text-white mt-7 md:mt-3 w-full text-sm md:text-base lg:text-lg font-semibold">Type
              of employment
              <div className="flex flex-col text-white w-full text-sm lg:text-base font-light">{typeOfEmployment}</div>
            </div>
          </div>
        </div>
        <div data-aos="fade-right" data-aos-offset="50"
             data-aos-easing="ease-in" data-aos-duration="500" data-aos-once="true"
             className="flex justify-end align-middle mt-7 md:mt-3 self-center items-center font-semibold"
             onClick={() => {
               window.open(`mailto:info@nebula.al?&subject=${title}`)
             }}>
          <img src={arrow} alt='arrow' className='h-4 md:h-6 hover:cursor-pointer'/>
        </div>
      </div>
    </div>
  )
};

const Career = ({data = {}}) => {
  const dispatch = useDispatch();
  const [availablePositions, setAvailablePositions] = useState([]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/open-job-positions?populate=deep`).then(response => {
      setAvailablePositions(response.data.data)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className="w-full h-full bg-[#000002]">
      <img src={gradient} className='absolute right-0 top-0' alt='blob'/>
      <div className="flex pt-36 lg:pt-20 md:pb-16 3xl:mx-auto max-w-[1700px] flex-col mx-5 lg:mx-10 xl:ml-20">
        <div
          className="z-30 font-semibold text-4xl sm:text-5xl lg:text-6xl xl:text-8xl text-white leading-7 text-left tracking-wide mb-5 md:mb-0 lg:pt-20 w-full lg::w-1/3">
          {data ? data?.title : <SkeletonLoader count={1}/>}
        </div>
        <div
          className='z-30 text-[#CCCCCC] xl:text-xl text-sm md:text-lg pb-10 md:py-10 text-left w-full md:w-2/3 lg:w-1/2'>
          {data ? data?.description : <SkeletonLoader count={1}/>}
        </div>
        <div className='flex flex-col xl:pr-20 z-30'>
          {availablePositions.length && availablePositions.map((jobPosition, index) => {
            return <AvailablePosition
              extraClasses={index % 2 ? "bg-gradient-to-tl from-[#ffffff1a] to-transparent" : "bg-gradient-to-bl from-[#ffffff1a] to-transparent"}
              title={jobPosition?.attributes?.title}
              placeOfEmployment={jobPosition?.attributes?.placeOfEmployment}
              applicationDueDate={jobPosition?.attributes?.applicationDueDate}
              typeOfEmployment={jobPosition?.attributes?.typeOfEmployment}/>
          })}
        </div>
      </div>
    </div>
  )
}

export default Career;

