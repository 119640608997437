import React, {useEffect, useState} from 'react';
import gradient from "@assets/svg/gradient.svg";
import AOS from 'aos';
import 'aos/dist/aos.css'
import CMSAPI from "@utils/plugins/CMSAPI";
import SkeletonLoader from "@core/loaders/SkeletonLoader";
import {hideSpinner, showSpinner} from "@redux/spinner/Action";
import {useDispatch} from "react-redux";

const Ffms = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState();

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, [])

  useEffect( () => {
    dispatch(showSpinner())
    CMSAPI.get(`/fishing?populate=deep`).then(response => {
      setData(response.data.data?.attributes)
    }).finally(() => dispatch(hideSpinner()))
  }, [dispatch]);

  return (
    <div className="w-full h-full bg-[#000002]">
      <img src={gradient} className='absolute right-0 top-0 z-30' alt='blob'/>
      <div className="flex pt-20 md:pb-16 mx-3 3xl:mx-auto max-w-[1700px] flex-col">
        <div className="flex py-6 3xl:mx-auto max-w-[1700px] flex-col">
          <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-5 md:px-12 xl:px-28 pt-8">
            <div className="flex flex-col xl:ml-10 3xl:ml-22 px-7 md:px-0">
              <div className="tracking-widest text-7xl font-semibold uppercase align-middle justify-center"
                   data-aos="fade-up" data-aos-offset="50" data-aos-once="true"
                   data-aos-easing="ease-in" data-aos-duration="500">{data ? data?.title : <SkeletonLoader count={1}/>}
              </div>
              <div className="flex mt-3 text-white text-sm uppercase tracking-wide justify-between w-48 px-1">
                <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in"
                     data-aos-duration="500" data-aos-once="true">{data ? data?.typeOfProject : <SkeletonLoader count={1}/>}
                </div>
                <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                     data-aos-once="true">{data ? data?.year : <SkeletonLoader count={1}/>}
                </div>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className='z-30 flex flex-col mt-2 text-base lg:text-lg font-thin w-full xl:w-5/6'>
              {data ? data?.description : <SkeletonLoader count={5}/>}
            </div>
          </div>
          <div className="z-30 grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-5 md:px-12 xl:px-28 pt-2 xl:pt-8">
            <img data-aos="flip-right" data-aos-offset="70" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true" src={data?.firstImage?.data?.attributes?.url}
                 alt="Ffms"
                 className='xl:ml-10 3xl:ml-22 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
            <img data-aos="flip-left" data-aos-offset="70" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 src={data?.secondImage?.data?.attributes?.url} alt="Ffms"
                 className='mt-5 md:mt-0 w-[300px] lg:w-[400px] lg:h-[400px] xl:w-[520px] xl:h-[520px] 3xl:w-[600px] rounded-lg'/>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 text-white mt-2.5 px-2 xl:px-28 pt-8">
            <div data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className="xl:ml-10 3xl:ml-22 font-bold text-2xl md:text-4xl text-[#d0eaf8] leading-7 text-center md:text-left md:w-4/5 flex justify-center items-center self-center">
              {data ? data?.firstParagraph?.title : <SkeletonLoader count={2}/>}
            </div>
            <div data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in" data-aos-duration="500"
                 data-aos-once="true"
                 className='mt-5 md:mt-0 text-white font-thin text-sm md:text-base lg:text-xl leading-tight md:text-justify md:w-4/5'>
              {data ? data?.firstParagraph?.description : <SkeletonLoader count={5}/>}
            </div>
          </div>
          <img src={data?.bannerImage?.data?.attributes?.url} alt="ffms" data-aos="fade-up" data-aos-offset="150" data-aos-easing="ease-in"
               data-aos-duration="500" data-aos-once="true"
               className='rounded-md w-full rounded-lg ml-5 md:ml-12 xl:ml-38 3xl:ml-48 w-[270px] md:w-[650px] lg:w-[900px] xl:w-[1070px] 3xl:w-[1270px] mt-12'/>
        </div>
      </div>
    </div>
  )
}

export default Ffms